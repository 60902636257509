import React from 'react'
import Footer from '../components/common/Footer'
import Navigation from '../components/common/Navigation'

export default function NotFound() {
  return (
    <>
      <Navigation />
      <div className="h-full">
        <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
          <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
            <div className="flex flex-shrink-0 justify-center">
              <a href="/" className="inline-flex">
                <span className="sr-only">Urban Fox</span>
                <img
                  className="h-12 w-auto"
                  src="/images/urban-fox-logo.png"
                  alt="urban fox logo"
                />
              </a>
            </div>
            <div className="py-16">
              <div className="text-center">
                <p className="text-base font-semibold text-uforange-600">404</p>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-ufblack sm:text-5xl">
                  Page not found.
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  Sorry, we couldn’t find the page you’re looking for.
                </p>
                <div className="mt-6">
                  <a
                    href="/"
                    className="text-base font-medium text-uforange-600 hover:text-uforgange-600"
                  >
                    Go back home
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}
